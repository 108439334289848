var el_close_lightbox = null;
var el_lightbox = null;
var el_lightbox_background = null;

if (document.readyState === "interactive") {
  const event = document.createEvent("Event");
  event.initEvent("turbolinks:load", true, true);
  document.dispatchEvent(event);
}

document.addEventListener("turbolinks:load", function() {
  if (document.title == 'Join | MentorPT') {
    var join_form = document.getElementById('join-form');
    document.body.addEventListener('ajax:success', (event) => {
      event.target.reset();
    });
    document.body.addEventListener('ajax:error', (event) => {
      console.log('Error');
    });
  }
});
